import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  BlockImageTextHalf,
  Cubes,
  Medailon,
  Media,
  PaddingWrapper,
  ReferenceBanner,
  ReferenceContainer,
  TitleH2,
  TitleH2Center,
} from "@igloonet-web/shared-ui"

import Logo from "../../../images/reference/royal-canin/logo-rc.png"
import Top from "../../../images/reference/royal-canin/top.png"
import Dvorak from "../../../images/reference/royal-canin/mdvorak.jpeg"
import Sideimg from "../../../images/reference/royal-canin/dokonala-skladacka-royal-canin.png"
import MobileScreen from "../../../images/reference/royal-canin/mobile-screen.png"
import DesktopScreen from "../../../images/reference/royal-canin/desktop-screen.png"
import Arm from "../../../images/reference/skinners/arm.svg"

class RoyalCanin extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>
            B2B databáze, e-shop a mikro web pro Royal Canin | igloonet
          </title>
          <meta
            name="description"
            content="Představení spolupráce s Royal Canin, výrobce krmiva pro domácí mazlíčky, pro kterého jsme vytvořili produktovou databázi, B2B e-shop a produktový web."
          />
        </Helmet>
        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          extodkaz="//royalcanin.com"
          heading="B2B databáze, e-shop a mikro web"
          odstavec="Realizace 2018"
        />

        <ReferenceContainer className="pt-5 mt-5">
          <Media
            iPhone={{
              backgroundSrc: MobileScreen,
              backgroundAlt: "",
            }}
            laptop={{
              backgroundSrc: DesktopScreen,
              backgroundAlt: "",
            }}
          />
          <PaddingWrapper>
            <TitleH2>Představení spolupráce</TitleH2>
            Společnost <b>Royal Canin</b> vyrábí superprémiová krmiva pro kočky
            a psy a distribuuje je do e-shopů, specializovaných obchodů,
            veterinárních klinik a chovatelům. Bohužel často naráží na problém,
            který zná dost výrobců – odběratelé neumí jejich{" "}
            <b>produkty správně prezentovat</b> a k <b>zákazníkům</b> se často
            dostávají neaktuální informace. Aktualizace informací pro odběratele
            se navíc prováděla manuálně a stála nemalé náklady na straně
            odběratelů.
            <p>
              Rozhodli jsme se pro Royal Canin celý proces komunikace s
              odběrateli automatizovat.
            </p>
          </PaddingWrapper>

          <PaddingWrapper>
            <TitleH2Center>Jak to funguje?</TitleH2Center>
            <p className="pb-5">
              V naší <b>B2B aplikaci</b> se shromažďuje kompletní informace o
              produktech, sklady se načítají ze systémů společnosti. Pokud Royal
              Canin přidává nový produkt, stačí v jednoduché šabloně vyplnit
              údaje o produktu, doplnit parametry, obrázky a produkt zveřejnit
              pro konkrétní trh. Odběratelé potom v různých strojově
              zpracovatelných formátech (podporujeme XML, CSV, XLS a další)
              dostanou informace ve formě feedu, který následně strojově
              zpracovávají na své straně.
            </p>
            <p>
              Nezůstalo ale jen u toho. Pro potřeby B2B jsme také implementovali
              možnost <b>hromadného nákupu</b>, opět přes jednoduché{" "}
              <b>strojově zpracovatelné</b> formáty. Odběratelé tak nemusí něco
              složitě „naklikávat”, ale používají své vlastní rozhraní nebo
              vytvoří jednoduchý Excelový soubor s objednávkou. Přidali jsme
              také možnost <b>opakování</b> objednávky, kdy dodavatel může
              jednoduše zopakovat kteroukoliv ze svých předchozích objednávek.
            </p>
          </PaddingWrapper>

          <PaddingWrapper>
            <BlockImageTextHalf
              className="d-md-none"
              image={Sideimg}
              alt={"ALT"}
              right
              hideImageOnSmall
            >
              <TitleH2>Jak to dopadlo?</TitleH2>

              <p>
                Nasazení našeho systému umožnilo Royal Canin <b>ušetřit 35 %</b>{" "}
                času na jejich call centru. Místo zpracování objednávek se teď
                jejich kolegyně mohou věnovat nabídkám služeb a péči o
                zákazníky. Počet objednávek, které jsou schopni odbavit stoupl o{" "}
                <b>celých 10 %</b>.
              </p>
              <p>
                Díky těmto úspěchům byl projekt B2B aplikace zařazen do soutěže
                projektů uvnitř koncernu Royal Canin s tématem{" "}
                <em>Make the Difference</em>. Naše aplikace vyhrála cenu jako
                nejlepší projekt za poslední 2 roky v celém CNE clusteru, tedy
                ze šesti zemí, které byly v rámci Royal Canin do soutěže
                zapojeny.
              </p>
              <Cubes
                data={[
                  {
                    description: "Snížení nákladů call centra",
                    number: "-35 %",
                  },
                  {
                    description: "Nárůst objednávek od odběratelů",
                    number: "+10 %",
                  },
                  {
                    description: "1. místo - nejlepší projekt",
                    image: Arm,
                  },
                ]}
              />
            </BlockImageTextHalf>
          </PaddingWrapper>

          <Medailon
            image={Dvorak}
            alt=""
            text="Při hledání vhodného partnera na tvorbu B2B aplikace jsem měl trochu strach,
            protože naše společnost je velmi specifická svým přístupem. Ovšem hned po první schůzce
            s igloonet jsem věděl, že jsou schopni se adaptovat na naše prostředí a hlavně myšlení.
            Po pár týdnech jsme spolupráci rozšířili a začali se zabývat řešením na pro naše další
            potřeby. Dodnes oceňuji jejich přístup, trpělivost a hlavně schopnost navrhnout řešení s
            logickým základem a dlouhodobým pohledem."
            name="Michal Dvořák"
            position="E-commerce specalist CZ/SK Royal Canin"
          />
        </ReferenceContainer>

        <ContactForm contact="sobol" />
      </Layout>
    )
  }
}

export default RoyalCanin
